import {theme as proTheme} from '@chakra-ui/pro-theme';
import {extendTheme, theme as baseTheme} from '@chakra-ui/react';

export const theme = extendTheme(
    {
        colors: {
            ...baseTheme.colors,
            brand: baseTheme.colors.blue,
        },
        styles: {
            global: () => ({
                body: {
                    '.ProseMirror': {
                        'ul, ol': {
                            padding: '0 1.2rem',
                        },
                        h1: {
                            fontSize: '1.25rem',
                        },
                        h2: {
                            fontSize: '1.15rem',
                        },
                    },
                },
            }),
        },
    },
    proTheme,
    {
        fonts: {
            heading: `'Figtree Variable', sans-serif;`,
            body: `'Figtree Variable', sans-serif;`,
        },
    }
);

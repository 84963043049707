import 'regenerator-runtime/runtime';
import '@fontsource-variable/figtree';
import de from 'date-fns/locale/de';
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import type {AppProps} from 'next/app';
import {NhostClient, NhostProvider} from '@nhost/nextjs';
import {ChakraProvider} from '@chakra-ui/react';
import {NhostApolloProvider} from '@nhost/react-apollo';

import {theme} from '../theme';

registerLocale('de', de);
setDefaultLocale('de');

import 'react-datepicker/dist/react-datepicker.css';
import dynamic from 'next/dynamic';

const nhost = new NhostClient({
    subdomain: process.env.NEXT_PUBLIC_NHOST_SUBDOMAIN,
    region: process.env.NEXT_PUBLIC_NHOST_REGION,
    autoRefreshToken: true,
    refreshIntervalTime: 10,
    autoSignIn: true,
});

const MyApp = ({Component, pageProps}: AppProps) => {
    const GleapWithNoSSR = dynamic(() => import('../components/gleap-chat'));
    return (
        <>
            <GleapWithNoSSR />
            <NhostProvider nhost={nhost} initial={pageProps.nhostSession}>
                <NhostApolloProvider nhost={nhost}>
                    <ChakraProvider theme={theme} portalZIndex={40}>
                        <Component {...pageProps} />
                    </ChakraProvider>
                </NhostApolloProvider>
            </NhostProvider>
        </>
    );
};

export default MyApp;
